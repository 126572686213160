





import { SMRoot } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import ymaps from "ymaps";

@Component
export default class Maps extends Vue {
  protected get styles() {
    return { height: `calc(100vh - ${SMRoot.fixed.height}px)` };
  }

  protected initMap() {
    ymaps
      .load("https://api-maps.yandex.ru/2.1/?lang=ru_RU")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((ymaps: any) => {
        const myMap = new ymaps.Map(
          "map",
          {
            center: [55.675088, 37.581393],
            zoom: 18,
            controls: [],
          },
          {
            searchControlProvider: "yandex#search",
          }
        );

        myMap.behaviors.disable("scrollZoom");
        myMap.controls.add("zoomControl", {
          size: "small",
        });

        const placemark = new ymaps.Placemark(
          myMap.getCenter(),
          {
            // Зададим содержимое заголовка балуна.
            balloonContentHeader:
              '<a href="/">ITWay</a><br>' + '<span class="description">IT-компания</span>',
            // Зададим содержимое основной части балуна.
            balloonContentBody:
              '<address style="font-style: normal">' +
              '<a href="tel:+7-499-550-55-95">+7 (499) 550-55-95</a>' +
              "<br />" +
              '<a href="mailto:info@itway.company">info@itway.company</a>' +
              "<br />" +
              "<span>117218, г. Москва</span>" +
              "<br />" +
              "<span>ул Кржижановского, д. 29</span>" +
              "<br />" +
              "<span>корпус 2, офис 2301</span>" +
              "</address>",
            // Зададим содержимое нижней части балуна.
            balloonContentFooter: 'Информация предоставлена:<br/>OOO "АйТиВэй"',
            // Зададим содержимое всплывающей подсказки.
            hintContent: "ITWay",
          },
          {
            preset: "islands#orangeDotIcon",
          }
        );
        // Добавим метку на карту.
        myMap.geoObjects.add(placemark);
        // Откроем балун на метке.
        placemark.balloon.open();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log("Failed to load Yandex Maps", error));
  }

  protected mounted() {
    this.initMap();
  }
}
